/**********************************************/
// Capa de Servicios para el modulo de Hoteles.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/hotels");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("response.data:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getData() {
    try {
      let response = await axios.get(this.#baseUrl + "/view/hotels");
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/hotels");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/hotels/${id}`);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData) {
    // console.log('Adding Record.', formData);
    try {
      let recordData = new FormData();
      recordData.append("id", formData.id);
      recordData.append("name", formData.name);
      recordData.append("description", formData.description);
      recordData.append("description_english", formData.description_english);
      recordData.append("establishment_type", formData.establishment_type);
      recordData.append("email", formData.email);
      recordData.append("address", formData.address);
      recordData.append("phone", formData.phone);
      recordData.append("web_page", formData.web_page);
      recordData.append("map_lat", formData.map_lat);
      recordData.append("map_lng", formData.map_lng);
      recordData.append("facebook_profile", formData.facebook_profile);
      recordData.append("instagram_profile", formData.instagram_profile);
      recordData.append("youtube_video", formData.youtube_video);
      recordData.append("servicehours", formData.servicehours);
      // console.log('Record-Data: ', recordData);
      let response = await axios.post(this.#baseUrl + "/hotels", recordData, {
          headers: this.header
        });
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    try {
      let response = await axios.put(`${this.#baseUrl}/hotels/${formData.id}`, {
        id: formData.id,
        name: formData.name,
        description: formData.description,
        description_english: formData.description_english,
        establishment_type: formData.establishment_type,
        email: formData.email,
        address: formData.address,
        phone: formData.phone,
        web_page: formData.web_page,
        map_lat: formData.map_lat,
        map_lng: formData.map_lng,
        facebook_profile: formData.facebook_profile,
        instagram_profile: formData.instagram_profile,
        youtube_video: formData.youtube_video,
        servicehours: formData.servicehours,
        active: formData.active,
      },
      {
        headers: this.header
      });
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      // console.log('Deleting Record: %s ', id);
      let response = await axios.delete(`${this.#baseUrl}/hotels/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(hotel_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        let formData = new FormData();
        formData.append("hotel_id", hotel_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/hotels",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          // console.log('Respuesta: ', response.data);
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(hotel_id, picture_id) {
    if (picture_id) {
      try {
        let formData = new FormData();
        formData.append("hotel_id", hotel_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/hotels",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          // console.log('Respuesta: ', response.data);
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-email/hotels?id=${profile_id}&email=${email}`
      );
      // console.log('Respuesta: ', response);
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      // console.log('error: ', error);
      // console.log('error.response: ', error.response);
      // console.log('error.response.data; ', error.response.data);
      //console.log(error.response.status);
      //console.log(error.response.headers);
      // console.log('Error response.data.message: ', error.response.data.message);
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
      // console.log('Error: ', error);
      // console.log('Error request: ', error.request);
      // console.log('Error request.data: ', error.request.data);
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
      // console.log('Another Error', error.message);
    }
    // console.log(error.config);
    return valueError;
  }
})();
